import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { deepEquals } from "../util";

const HoverContext = React.createContext({
    state: {
        position: null,
        component: null
    },
    setState: (s) => { },
    hoverElement: null,
});

export const HoverProvider = ({ children, ...props }) => {
    const state = useRef({ position: null, component: null });
    const el = useRef(null);
    const wrap = useRef(null);

    if (el.current === null) {
        const element = document.createElement('div');
        element.classList.add('media-hover-preview');
        // element.style.display = 'none';

        const wrapper = document.createElement('div');
        wrapper.classList.add('media-hover-wrapper');

        wrapper.appendChild(element);

        document.body.appendChild(wrapper);
        el.current = element;
        wrap.current = wrapper;
    }

    useEffect(() => {

    }, [state, el]);

    const setState = useCallback(s => {
        state.current = s;

        const position = true;//s?.position;

        if (position) {
            el.current.style.display = '';
            wrap.current.style.transform = s?.position ? makeTransform(s.position) : '';
        } else {
            el.current.style.display = 'none';
            wrap.current.style.transform = '';
        }

        // console.log(position);
    }, []);

    return (<HoverContext.Provider value={{ state, setState, hoverElement: el.current }}>
        {/* <div className="media-hover-preview" style={style} ref={el}>
            {component}
        </div> */}

        {children}
    </HoverContext.Provider>);
};

function makeTransform({ x, y }) {
    const vw = document.documentElement.clientWidth;
    const vh = document.documentElement.clientHeight;

    const halfVw = vw / 2;
    const halfVh = vh / 2;

    const alignX = x < halfVw ? 1 : -1;
    const alignY = y < halfVh ? 1 : -1;

    x += alignX * 12;
    y += alignY * 12;

    if (x < 32) {
        x = 32;
    }

    if (y < 32) {
        y = 32;
    }

    if (x > vw - 32) {
        x = vw - 32;
    }

    if (y > vh - 32) {
        y = vh - 32;
    }

    const offsetX = alignX * 50;
    const offsetY = alignY * 50;

    // return `translate(${x}px, ${y}px)`;
    return `translate(-50%, -50%) translate(${x}px, ${y}px) translate(${offsetX}%, ${offsetY}%)`;
}


export const Hoverable = memo(function Hoverable_m(props) {
    const { setState: setHoverr, hoverElement } = React.useContext(HoverContext);
    const { children, component, render, ...rest } = props;

    const [hover, setHoverState] = useState(null);

    const setHover = useCallback(v => {
        setHoverState(v);
        setHoverr(v);
    }, [setHoverr]);

    const onMouseOver = useCallback(e => setHover({ position: { x: e.clientX, y: e.clientY } }), [setHover]);
    const onMouseMove = useCallback(e => setHover({ position: { x: e.clientX, y: e.clientY } }), [setHover]);
    const onMouseOut = useCallback(e => setHover(null), [setHover]);

    // useEffect(() => {
    //     console.log(hover, hoverElement);
    // }, [hover]);

    // if (render) {
    //     return render();
    // }

    // We need to forward the props into the component (listeners etc)
    // const { children: componentChildren } = children[0] || {};
    let comp = useMemo(() => React.cloneElement(children, { ...rest, onMouseOver, onMouseMove, onMouseOut }), [rest, children, onMouseOver, onMouseMove, onMouseOut]);
    // return useMemo(() => React.cloneElement(children, { ...rest, onMouseOver, onMouseMove, onMouseOut }), [children, onMouseMove, onMouseOver, onMouseOut, rest]);
    // return <>Black poop</>
    // return <div>{render()}</div>
    return <>
        {comp}
        {(hover && hoverElement) ? createPortal(component, hoverElement) : null}
    </>;
}, deepEquals);
