import { memo } from "react";
import Timeago from 'react-timeago';

import { SettingFilled, FontSizeOutlined, PictureFilled, WarningFilled } from "@ant-design/icons";
import { Col, Card, Button } from "antd/es";

import { sortArgs, prepareArgsForRender, deepEquals, deepEqualsDebugMemo } from '../util';

import ResponsiveImage from "./ResponsiveImage";
import { useSettings } from "./RecentResults";



const ValidationImage = memo(({ data, modal, changed }) => {
    const { previewMultiplier, hideTime, combineImages, imgIdx } = useSettings()[0];

    const { images: all_images, run_id: run } = data;
    const time = data["@timestamp"];

    all_images.sort();
    const url = `https://public.dreamshaper.io/results/${run}/previews/${all_images[imgIdx]}`//

    let allUrls = [];
    for (const image of all_images) {
        allUrls.push(`https://public.dreamshaper.io/results/${run}/previews/${image}`);
    }

    // Rename argument entries in data.args
    const args = prepareArgsForRender(data.args);

    const icons = [];
    if (args['train_text_encoder']) {
        icons.push(<FontSizeOutlined />);
    }

    const title = <span style={{ fontSize: 12 }}>
        {`#${data.step}`}


        {changed ? <span style={{ backgroundColor: '#DDDD22', color: '#222', padding: '2px 4px', borderRadius: 4, marginLeft: 4, border: '1px solid #ffffff60' }}><WarningFilled style={{ fontSize: 12 }} /></span> : null}
    </span>;
    // T${data.args.text_rank} U${data.args.rank}

    //
    const filterImages = () => {
        const [date, time] = args.output_dir.split('/').slice(-2);
        const path = `20${date}/${time}`

        const ev = new Event("set-image-filter", { bubbles: false });
        ev.imageFilter = `loras.path = "${path}/checkpoint-${data.step}"`;
        ev.evals = true;
        window.dispatchEvent(ev);
    }

    const extra = (<>
        <Button className="params-button" type="link" size="small" onClick={() => {
            modal.info({
                width: 800,
                height: '80%',
                maskClosable: true,
                title: "Experiment parameters",
                content: (
                    <div style={{ overflow: 'auto', maxHeight: 900 }}>
                        <pre style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}>{JSON.stringify(sortArgs(args), null, 4)}</pre>
                    </div>
                )
            })
        }}>
            <SettingFilled style={{ fontSize: 12 }} />
        </Button>

        <Button className="params-button" type="link" size="small" onClick={() => filterImages()}><PictureFilled style={{ fontSize: 12 }} /></Button>
    </>);

    const sizes = {
        xxl: 3 * previewMultiplier,
        xl: 3 * previewMultiplier,
        md: 3 * previewMultiplier,
        sm: 6 * previewMultiplier,
        xs: 12 * previewMultiplier
    };

    return (
        <Col {...sizes}>
            <Card className="image-card" title={title} extra={extra}>
                <div>
                    {combineImages ? (
                        <div className="image-wrapper" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {allUrls.map((url, idx) =>
                                <ResponsiveImage key={idx} width={'33%'} src={url} />
                            )}
                        </div>
                    ) :
                        <ResponsiveImage src={url} width={'100%'} />
                    }

                    {!hideTime && <div className="time-ago">
                        <Timeago style={{ fontSize: 11 }} date={new Date(time)} live={true} minPeriod={1} maxPeriod={1} />
                    </div>}

                    <div className="icons">
                        {icons}
                    </div>
                </div>
            </Card>
        </Col>
    );
}, deepEqualsDebugMemo("ValidationImage"));


export default ValidationImage;