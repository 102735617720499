import { useEffect } from "react";

/**
 * React hook to call a function at a specified interval. This hook will automatically clear the 
 * timer reference when the component unmounts,
 * 
 * @param {TimerHandler} timer The timer function to be called once per interval. Warning: to avoid reference 
 * mismatch (and thus expensive rerenders), wrap the timer inside useCallback().
 * @param {number} interval The interval in milliseconds to call the timer function.
 * @param {boolean | undefined} skipInitial If true, the timer will only start after the first interval.
 */
function useInterval(timer, interval, skipInitial, deps = []) {
    useEffect(() => {
        const callback = () => {
            if (!skipInitial)
                timer();
        };

        callback();

        let key = setInterval(callback, interval);
        return () => clearInterval(key);
    }, [skipInitial, interval, ...deps]);
}

const RENAME = { 'text_rank': 'rank_text' };

const IGNORED = ['alpha', 'cast_vae', 'crops_coords_top_left_h', 'crops_coords_top_left_w',
    'instance_data_dir', 'local_rank', 'logging_dir', 'negative_validation_prompt', 'num_class_images',
    'num_validation_images', 'output_dir', 'pre_offset', 'pretrained_model_name_or_path',
    'pretrained_vae_model_name_or_path', 'prior_generation_precision', 'prior_loss_weight', 'push_to_hub',
    'report_to', 'resolution', 'resume_from_checkpoint', 'revision', 'train_text_encoder',
    'use_8bit_adam', 'with_prior_preservation', 'validation_epochs', 'enable_xformers_memory_efficient_attention',
    'dataloader_num_workers', 'allow_tf32', 'autocaption', 'center_crop', 'checkpointing_steps',
    'checkpoints_total_limit', 'class_data_dir', 'class_prompt'];

const sortArgs = (args) => {
    const sorted = {};

    Object.keys(args)
        .map((k) => k)
        .sort()
        // .filter((k) => !IGNORED.includes(k))
        .forEach((k) => (sorted[k] = args[k]));

    return sorted;
};


const recursiveObjectSort = obj => obj ? Object.keys(obj)
    .sort()
    .reduce((a, v) => {
        a[v] = obj[v];

        // If the value is an object, sort it too
        if (typeof obj[v] === 'object') {
            a[v] = recursiveObjectSort(obj[v]);
        }

        return a;
    }, {}) : obj;

function prepareArgsForRender(data) {
    const args = {};

    Object.keys(data).forEach((k) => {
        const kdest = RENAME.hasOwnProperty(k) ? RENAME[k] : k;
        args[kdest] = data[k];

        if (kdest === 'learning_rate_text' && !args['train_text_encoder']) {
            args[kdest] = "no";
            return;
        }

        if (kdest === 'learning_rate' || kdest === 'learning_rate_text') {
            args[kdest] = Number.parseFloat(data[k]).toExponential(1);
        }
    });

    return args;
}

export function deepEqualsDebugMemo(tag) {
    return (a, b) => deepEqualsDebug(a, b, tag);
}

export function deepEqualsDebug(a, b, tag, ...path) {
    // Short-circuit for most primitives etc.
    // The typeof value is only needed for `a`, as the result must then subsequently pass
    // the `Object.is` check, which will do the type check in native land.
    const is = Object.is(a, b);
    if (is || typeof (a) !== 'object' || typeof (b) !== 'object' || a === null || b === null || a === undefined || b === undefined) {
        if (!is) {
            console.log(`deepEquals[${tag}] failed at key ${path.join('.')}: Object.is identity comparison`);
        }

        return is;
    }

    const aProto = a.__proto__;
    const bProto = b.__proto__;

    // Different types of objects
    if (aProto !== bProto) {
        // Special handling of NaN since they're never passing the === check (which is how you detect NaNs)
        if (isNaN(a) && isNaN(b)) {
            return true;
        }

        console.log(`deepEquals[${tag}] failed at key ${path.join('.')}: prototype inequality`);
        return false;
    }

    // Prototypes are equal at this point so we check only A's proto
    if (aProto === Object.prototype) {
        // The order of the entries returned is well-defined in the spec, so we can rely on 
        // the equality check to always succeed in the correct order of A's entries against B's.
        const entA = Object.entries(a);
        const entB = Object.entries(b);

        const lenA = entA.length;

        // Length lets us short-cut if different keys; likely common.
        if (lenA === entB.length) {
            // The reason we check for value right after each key is due to the common case of values
            // differing more often than property names. As such, we're more likely to find a mismatch
            // quicker. Since we're not dealing with perfect string memory blocks either, we don't
            // get much CPU cache benefit from doing things sequentially batched.
            for (let i = 0; i < lenA; i++) {
                // Check property key (string comparison, we skip `Object.is`)
                if (entA[i][0] !== entB[i][0]) {
                    console.log(`deepEquals[${tag}] failed at key ${path.join('.')}: object value inequality`);
                    return false;
                }

                // Compare value with recursive call to self
                if (!deepEqualsDebug(entA[i][1], entB[i][1], tag, ...path, entA[i][0])) {
                    // This one already logs since it calls itself.
                    return false;
                }
            }

            // If none of the props failed, it's equal
            return true;
        } else {
            console.log(`deepEquals[${tag}] failed at key ${path.join('.')}: object key count mismatch`);
            return false; // Length mismatch
        }
    } else if (aProto === Array.prototype) {
        const lenA = a.length;
        const lenB = b.length;

        if (lenA === lenB) {
            for (let i = 0; i < lenA; i++) {
                if (!deepEqualsDebug(a[i], b[i], tag, ...path, i.toString())) {
                    // This one already logs since it calls itself.
                    return false;
                }
            }

            // All entries passed the check, so we assume it is equal.
            return true;
        } else {
            console.log(`deepEquals[${tag}] failed at key ${path.join('.')}: array length mismatch`);
            return false; // Length mismatch
        }
    } else {
        // In this case, the prototype was equal but not a recognized object. (e.g. DOM event, Error, etc)
        // Since we can never really know how to compare these, and Object.is didn't return true, we assume inequality for safety.
        console.log(`deepEquals[${tag}] failed at key ${path.join('.')}: prototype unhandled:`, aProto);
        return false;
    }
}

export function deepEquals(a, b) {
    // Short-circuit for most primitives etc.
    // The typeof value is only needed for `a`, as the result must then subsequently pass
    // the `Object.is` check, which will do the type check in native land.
    const is = Object.is(a, b);
    if (is || typeof (a) !== 'object' || typeof (b) !== 'object' || a === null || b === null) {
        return is;
    }

    const aProto = a.__proto__;
    const bProto = b.__proto__;

    // Different types of objects
    if (aProto !== bProto) {
        // Special handling of NaN since they're never passing the === check (which is how you detect NaNs)
        if (isNaN(a) && isNaN(b)) {
            return true;
        }

        return false;
    }

    // Prototypes are equal at this point so we check only A's proto
    if (aProto === Object.prototype) {
        // The order of the entries returned is well-defined in the spec, so we can rely on 
        // the equality check to always succeed in the correct order of A's entries against B's.
        const entA = Object.keys(a);
        const entB = Object.keys(b);

        const lenA = entA.length;

        // Length lets us short-cut if different keys; likely common.
        if (lenA === entB.length) {
            // The reason we check for value right after each key is due to the common case of values
            // differing more often than property names. As such, we're more likely to find a mismatch
            // quicker. Since we're not dealing with perfect string memory blocks either, we don't
            // get much CPU cache benefit from doing things sequentially batched.

            /* Old:
            for (let i = 0; i < lenA; i++) {
                // Check property key (string comparison, we skip `Object.is`)
                if (entA[i][0] !== entB[i][0]) {
                    return false;
                }

                // Compare value with recursive call to self
                if (!deepEquals(entA[i][1], entB[i][1])) {
                    return false;
                }
            }*/

            for (let i = 0; i < lenA; i++) {
                // const k = entA[i];
                if (entA[i] !== entB[i]) {
                    return false;
                }

                if (!deepEquals(a[entA[i]], b[entB[i]])) {
                    return false;
                }
            }

            // If none of the props failed, it's equal
            return true;
        } else {
            return false; // Length mismatch
        }
    } else if (aProto === Array.prototype) {
        const lenA = a.length;
        const lenB = b.length;

        if (lenA === lenB) {
            for (let i = 0; i < lenA; i++) {
                if (!deepEquals(a[i], b[i])) {
                    return false;
                }
            }

            // All entries passed the check, so we assume it is equal.
            return true;
        } else {
            return false; // Length mismatch
        }
    }

    // In this case, the prototype was equal but not a recognized object. (e.g. DOM event, Error, etc)
    // Since we can never really know how to compare these, and Object.is didn't return true, we assume inequality for safety.
    return false;
}


const RELEVANT = ['accumulate_text', 'adam_weight_decay', 'gradient_accumulation_steps', 'learning_rate',
    'learning_rate_text', 'lr_warmup_steps', 'merge_params', 'mixed_precision', 'mse_reduction', 'no_lora',
    'nonlinear_timesteps', 'num_train_epochs', 'patch_mlp', 'rank', 'text_rank', 'train_batch_size',
    'nonlinear_cutoff', 'adam_beta1', 'adam_beta2', 'adam_epsilon', 'prodigy_d0', 'prodigy_max_growth_rate',
    'prodigy_safeguard_warmup', 'prodigy_bias_correction', 'single_timestep_value', 'prodigy_d_coef', 'single_timestep_range'];


const FRIENDLY_NAMES = {
    'accumulate_text': 'Text accum.',
    'adam_weight_decay': 'Weight decay',
    'gradient_accumulation_steps': 'Grad. steps',
    'learning_rate': 'LR',
    'learning_rate_text': 'Text LR',
    'lr_warmup_steps': 'Warmup',
    'merge_params': 'Merge params',
    'mixed_precision': 'Float type',
    'mse_reduction': 'MSE mode',
    'no_lora': 'Disable LoRA',
    'nonlinear_timesteps': 'Nonlinear steps',
    'num_train_epochs': 'Epochs',
    'patch_mlp': 'Patch MLP',
    'rank': 'Rank',
    'text_rank': 'Text rank',
    'train_batch_size': 'Batch Size',
};

const friendlyName = (k) => FRIENDLY_NAMES[k] || k;


// Returns -1 if the parameter decreased, 1 if it increased, and 0 if it remained the same.
const paramDelta = (changes, p) => {
    if (changes.length === 0) {
        return 0;
    }

    const change = changes.find(c => c.param === p);
    if (change) {
        // Check if it is a numeric value, and compare them numerically.
        if (typeof change.from === 'number') {
            return change.from < change.to ? 1 : -1;
        }

        return 0;
    }

    return 0;
};

// Returns 'inc' if the parameter increased, 'dec' if it decreased, and '' if it remained the same.
const paramClass = (changes, p) => {
    const d = paramDelta(changes, p);
    if (d === 1) {
        return 'inc';
    }
    if (d === -1) {
        return 'dec';
    }
    return '';
};

export {
    useInterval,
    sortArgs,
    prepareArgsForRender,
    friendlyName,
    paramDelta, paramClass,
    recursiveObjectSort,
    RELEVANT
}