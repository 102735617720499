import * as zustand from 'zustand';
import { immer } from 'zustand/middleware/immer';

function computeModelLookup(models, styleModels) {
    const lookup = {};

    for (const model of models) {
        lookup[model] = true;
    }

    for (const model of Object.keys(styleModels)) {
        lookup[model + "/unet"] = true;
    }

    return lookup;
}

const store = zustand.create(
    immer(set => ({
        models: [],
        vaeModels: [],
        styleModels: {},
        modelLookup: {},
        remoteModels: [],
        imageFilter: null,
        selection: [], // Pathnames
        diskUsage: null,

        setModels(models) {
            return set(next => {
                next.models = models;
                next.modelLookup = computeModelLookup(models, next.styleModels);
            });
        },

        setVaeModels(models) {
            return set(next => {
                next.vaeModels = models;
            });
        },

        setStyleModels(styleModels) {
            return set(next => {
                next.styleModels = styleModels;
                next.modelLookup = computeModelLookup(next.models, styleModels);
            });
        },

        setRemoteModels(models) {
            return set(next => {
                next.remoteModels = models;
            });
        },

        setImageFilter(filter) {
            return set(next => {
                next.imageFilter = filter;
            });
        },

        setDiskUsage(usage) {
            return set(next => {
                next.diskUsage = usage;
            });
        },

        select(pathname) {
            return set(next => {
                next.selection.push(pathname);
            });
        },

        deselect(pathname) {
            return set(next => {
                next.selection.splice(next.selection.indexOf(pathname), 1);
            });
        },

        clearSelection() {
            return set(next => {
                next.selection = [];
            });
        }
    }))
);

const functions = {
    useModels: () => store(s => s.models),
    useStyleModels: () => store(s => s.styleModels),
    useVaeModels: () => store(s => s.vaeModels),
    useModelLookup: () => store(s => s.modelLookup),
    useSetModels: () => store(s => s.setModels),
    useSetStyleModels: () => store(s => s.setStyleModels),
    useSetVaeModels: () => store(s => s.setVaeModels),
    useImageFilter: () => store(s => s.imageFilter),
    useDiskUsage: () => store(s => s.diskUsage),
    useSetDiskUsage: () => store(s => s.setDiskUsage),
    useRemoteModels: () => store(s => s.remoteModels),
    useSetRemoteModels: () => store(s => s.setRemoteModels),
    useSetImageFilter: () => store(s => s.setImageFilter),
    useSelection: () => store(s => s.selection),
    useClearSelection: () => store(s => s.clearSelection),
    useById: id => store(s => s.models.find(m => m.id === id))
};

const modelStorage = { use: store, ...functions };
export default modelStorage;